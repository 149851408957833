import { Colors } from 'theme/variables';

export default `
  /*
  @author Song Wang
  */

  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    // color: #7D8B99;
  }

  .token.punctuation {
    // color: #5F6364;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.function-name,
  .token.constant,
  .token.symbol,
  .token.deleted {
    // color: #c92c2c;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.function,
  .token.builtin,
  .token.inserted {
    // color: #2f9c0a;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .token.variable {
    // color: ${Colors.h};
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword,
  .token.class-name {
    color: ${Colors.p};
    font-weight: 600;
  }

  .token.regex,
  .token.important {
    // color: #e90;
  }

  .language-css .token.string,
  .style .token.string {
    // color: #a67f59;
  }

  .token.important {
    font-weight: normal;
  }

  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .namespace {
    opacity: .7;
  }

  /* Plugin styles */
  .token.tab:not(:empty):before,
  .token.cr:before,
  .token.lf:before {
    // color: #e0d7d1;
  }

  /* Plugin styles: Line Numbers */
  pre[class*="language-"].line-numbers {
    padding-left: 0;
  }

  pre[class*="language-"].line-numbers code {
    padding-left: 3.8em;
  }

  pre[class*="language-"].line-numbers .line-numbers-rows {
    left: 0;
  }

  /* Plugin styles: Line Highlight */
  pre[class*="language-"][data-line] {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  pre[data-line] code {
    position: relative;
    padding-left: 4em;
  }
  pre .line-highlight {
    margin-top: 0;
  }
`;
